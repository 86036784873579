.dangerModal {
  border-radius: 50px !important;
}
.icon {
  cursor: pointer !important;
}

.actions {
  background-color: white !important;
}
