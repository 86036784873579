img[alt="transaction connect logo"] {
    height: 3.5rem;
}
.login-page {
    padding-top: 12vw!important;
}
#formConnection {
    font-size: 1rem;
    width: 25rem;
    margin: auto;
    background-color: white;
    padding: 1rem;
    border: 1px solid rgba(128, 128, 128, 0.13);
}
.connection {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 100;
    color: #56c9ef
}

.login-page h2 {
    font-size: 13px;
}

.login-page p {
    max-width: 25rem;
    margin:1rem auto ;
    text-align: initial;
    font-size: 11px;
    margin-top: 2rem;
}

.login-page p > span {
    font-weight: bold;
}
