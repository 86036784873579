.ui.small.table {
  white-space: nowrap;
  width: 100%;
  font-size: 11px;
}

.ui.search.dropdown {
  margin-left: 1rem;
}

.validate-association label {
  font-size: 13px;
  font-weight: 700;
}

.validate-association p {
  font-size: 12px;
  margin: 14px 0;
}

#table-scroll {
  margin: 1rem 2rem;
}

.ui.dimmer .ui.loader {
  color: #1c3a52;
}
.ui.dimmer {
  border-radius: inherit!important;
  height: 78vh;
}

.ui.dimmer .ui.loader:after {
  border-color: #1c3a52 transparent transparent;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  -ms-flex: 1 0 0px;
  flex: 1 0;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 2px 2px;
  overflow: hidden;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  -webkit-transition-property: width,min-width,padding,opacity;
  -o-transition-property: width,min-width,padding,opacity;
  transition-property: width,min-width,padding,opacity;
}

.ui.dimmer {
  background-color: transparent!important;
}

.ReactTable .rt-tr {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
}
.ui.table tfoot tr:first-child>th:only-child {
  border-radius: 0 0 .28571429rem .28571429rem;
  position: absolute;
  bottom: -4rem;
}
.ui.table tfoot th {
  cursor: auto;
   border-top: none;
  text-align: inherit;
  background-color: transparent;
  color: rgba(0,0,0,.87);
  padding: .78571429em .78571429em;
  vertical-align: middle;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
}

.ui.pagination.menu .item {
  min-width: 2.5rem;
  text-align: center;
  font-size: 11px;
}

#table-scroll {
  font-size: 11px;
  text-align: left;
}

.ReactTable .rt-thead.-header {
   box-shadow: none;
  border-bottom: 1px solid #8080801a;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
  outline: none!important;
}

.loader-container {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container-pattern {
  display: flex;
  justify-content: center;
  align-items: center;
}

