.ui.inverted.menu {
  border: 0 solid transparent;
  background: #042040!important;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: fixed;
  height: 100vh;
  top: 0;
  text-align: left;
  width: 13vw!important;
  padding: 1rem!important;
}

.main-nav img {
  width: 11vw;
  padding: 1.5rem;
}

.ui.top.attached.header {
  height: 7.5rem;
  margin-bottom: 0;
  border-radius: .28571429rem .28571429rem 0 0;
  /* height: 10rem; */
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  border-bottom: none;
}
.ui.attached.message {
  margin-bottom: -1px;
  border-radius: .28571429rem .28571429rem 0 0;
  -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
  box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: 1.5rem;
}

.bm-item li {
  list-style: none;
  text-align: left;
  margin: 5rem auto;
  font-size: 1.4rem;

}
.bm-item li > a {
  color: white;
}


.bm-item {

  padding-left: 0;
}


.ui.menu .item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: 0 0;
  padding: 0.728571em 0.54285714em;
  text-transform: none;
  color: rgba(0,0,0,.87);
  font-weight: 400;
  -webkit-transition: background .1s ease,box-shadow .1s ease,color .1s ease;
  -webkit-transition: background .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
  transition: background .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
  -o-transition: background .1s ease,box-shadow .1s ease,color .1s ease;
  transition: background .1s ease,box-shadow .1s ease,color .1s ease;
  transition: background .1s ease,box-shadow .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
}

.main-nav img {
  width: 10vw;
  padding: 2rem;
  position: absolute;
  top: 0;
}

.ui.inverted.vertical.menu .item .menu .active.item {
  background: 0 0;
  color: #00b5ad!important;
}

.ui.vertical.menu {
  width: 13vw;
  /* top: 50%; */
  height: 100vh;
  text-align: left;
  -ms-flex-pack: center;
  /* justify-content: center; */
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  padding-top: 6rem!important;
}
@media (max-width: 667px) {
  .ui.vertical.menu {
    width: 23vw!important;
    padding: 1rem 0.1rem!important;
  }
}

.ui.vertical.menu .item>i.icon {
  /* width: 14.18em; */
  /* float: right; */
  /* margin: 0 0 0 .5em; */
  font-size: 22px;
  color: #FF4070;
}

.ui.inverted.menu {
  border: 0 solid transparent;
  background: #1f2532;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 12;
  border-radius: 0;
}

.ui.vertical.pointing.menu .menu .active.item:after {
  background-color: #01b4a9!important;
}

.ui.inverted.pointing.menu .active.item:after {
  background: #3d3e3f!important;
  margin: 0!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  border: none!important;
  margin-right: 10px!important;
}


.ui.vertical.menu .item>.header:not(.ui) {
  text-transform: uppercase;
  font-size: 10px;
}
