.test {
  max-width: 900px;
  margin: auto;
  margin-top: 2rem;
}


.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
  position: relative;
  padding-left: 0!important;
  padding-right: 1.5em!important;
  margin-top: 1rem;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  -ms-flex: 1 0 0px;
  flex: 1 0;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 2px 10px;
  overflow: hidden;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  -webkit-transition-property: width,min-width,padding,opacity;
  -o-transition-property: width,min-width,padding,opacity;
  transition-property: width,min-width,padding,opacity;
}


.bla {
  background: rgba(9, 30, 66, 0.54);
  transition: opacity 220ms;
}

.invalid-regex {
  display: inline-block;
  position: absolute;
  bottom: -21px;
  font-size: 12px;
  color: #9f3a38;
}

.ui.form .fields {
  -ms-flex-pack: center;
  justify-content: center;
  align-items: flex-end;
}
.ui.dimmer {
  background: rgba(9, 30, 66, 0.54)!important;
  transition: opacity 220ms;
  min-height: 100vh;
}

.selected-pattern {
  font-size: 15px;
  background-color: #ffeb3b42;
  color: black;
  padding: .5rem;
  margin-left: 1rem;
  font-weight: 800;
}
.store-general-info-pattern {
  display: -ms-flexbox;
  display: flex;
  margin: 3rem auto 0;
  width: 96.5%;
  justify-content: space-between;
}

.store-general-info-pattern > div:first-of-type {
  width: 49%;
  height: 250px;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 1.2rem;
  color: #202532;
}

.store-general-info-pattern > div:first-of-type  p {
  margin: 5px 0!important;
}

.store-general-info-pattern > div:first-of-type  span {
  font-weight: 600;
  margin-left: 5px;
}

.store-general-info-pattern > div{
  width: 49%
}


.entity-management-container > article {
  margin: 12px auto;
  width: 96.5%;
}

.entity-management-container > article:last-of-type {
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.ReactTable .-pagination {
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: none!important;
  border-top: 1px solid rgba(0,0,0,0.1);
}


.ui.green.button:focus, .ui.green.buttons .button:focus {
  background-color: #00b5ad;
  color: #fff;
  text-shadow: none;
}

.test-pattern-btn {
  color: white!important;
  background-color: #2185d0!important;
}

.ui.modal>.header {
  display: block;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: 0;
  padding: .8rem 1.5rem!important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0,0,0,.85);
  border-bottom: 1px solid rgba(34,36,38,.15);
  background-color: #042040!important;
  color: white;
  font-size: 14px!important;
  letter-spacing: .6px;
}

.nav-store-retailer-management {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  border: none!important;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.ui.header>.icon {
  font-size: 1em!important;
}


.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 43px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: 10px;

}

#root > section > main > section > section > div > div:nth-child(2) > div > form > div > div.Select.is-clearable.is-searchable.Select--single {
  font-size: 10px;
  padding: 0!important;
}


.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 42px;
  height: 100%;
}


#root > section > main > section > section > div > div:nth-child(2) > div > form > div > div.Select.is-focused.is-open.is-searchable.Select--single {
  font-size: 10px;
  padding: 0!important;
}

#root > section > main > section > section > div > div:nth-child(2) > div > form > div > div.Select.is-searchable.Select--single {
  font-size: 10px;
  padding: 0!important;
  width: 25rem;
  margin-right: 5px;
}
#root > section > main > section > section > div > div:nth-child(2) > div > form > div > div.Select.has-value.is-focused.is-pseudo-focused.is-searchable.Select--single > input[type="hidden"] {
  border: none!important;
  outline: none!important

}

#root > section > main > section > section > div > div:nth-child(2) > div > form > div > div.Select.has-value.is-focused.is-pseudo-focused.is-searchable.Select--single {
  border: none!important;
  outline: none!important
}

#react-select > div.Select-input > input {
  border: none!important;
}

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.virtual-select {
  width: 25rem;
  font-size: 10px;
  margin-right: 5px!important;
  margin-left: 5px!important;
}
.virtual-select input {
  border: none!important;
}

.Select-input > input {
  border: none!important;

}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color:#00000078!important;
}
.Select.has-value.Select--single input {
  height: 68%;
  font-size: 11px!important;
}
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#react-select-2--value > div.Select-input > input {
  border: none!important
}

.Select-input {
  height: 31px!important;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333;
  padding-top: 6px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.Select-placeholder, .Select--single > .Select-control .Select-value {
  height: 100%!important;
}


#react-select-2--value > div.Select-input {
  width: 100%;
}

#react-select-3--value > div.Select-input > input {
  border: none!important;
}
#root > section > main > section > section > div > div.ui.green.info.attached.message > p {
  margin: 0!important;
}

@media only screen and (max-width: 768px) {
  .nav-store-retailer-management form  {
    width: 100%;
    display: flex;
    flex-direction: column;

  }
  .nav-store-retailer-management-form  {
    width: 100%;
    display: flex;
    flex-direction: column;

  }
  .nav-store-retailer-management form .field {
    width: 100%!important;
  }
  .nav-store-retailer-management form button {
    width: 100%!important;
    padding: 0 .5em;
  }

  .nav-store-retailer-management .ui.blue.button, .nav-store-retailer-management button {
    width: 100%!important;
    padding: 0 .5em!important;
    height: 3rem;

  }
  .nav-store-retailer-management .ui.blue.button {
    width: 100%!important;
    padding: 0 .5em!important;
    margin-top: 1rem!important;
  }
  .store-general-info-pattern {
    flex-direction: column;
  }
  .store-general-info-pattern > div:first-of-type {
    width: 100%;
  }
  .store-general-info-pattern > div {
    width: 100%;
    margin-bottom: 2rem;
  }
  .plus.circular.icon {
    display: none;
  }
}
