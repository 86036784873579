.columnStats {
  padding-right: 0 !important;
}
.columnVariation {
  //padding-left: 0 !important;
}

.sizeStatistic {
  font-size: 14px !important;
}
