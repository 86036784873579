.ui.loader:after, .ui.loader:before {
    margin-left: 120px !important;
    margin-top: 25px !important;
}
.stat-header {
    background-color: #1f2532 !important;
    color: white !important;
    font-size: 50px !important;
    margin-left: 180px !important;
}
.data {
    margin-top: -20px !important;
}

.customer-header {
    color: white !important;
    margin-right: 18% !important;
}
.customerReg-header {
    color: white !important;
    margin-right: 22% !important;
}
.desync-header {
    color: white !important;
    margin-right: 20% !important;
}
.div-customer {
    width: 350px;
    margin-top: 10px;
}
.customer-div {
    width: 87vw!important;
    position: relative!important;
    margin-left: 13vw;
    text-align: center;
}
.header-chart {
    color: white;
    margin-right: 8%;


}
.chart-div {
    margin-left: 10%;
    width: 80%;
    display: inline-block;
}
.DateInputDiv {
    margin-right: 88%;
    display: flex;
}


#reactgooglegraph-1 > div > div:nth-child(1) > div > svg > g:nth-child(5) > g > text {
    color: white!important;
}

.chartTitle {
    color: white;
}
