.ui.primary.button, .ui.primary.buttons .button {
    margin-bottom: 2rem;
}

.ui.message .header+p {
    text-align: center!important;
}

.ui.tiny.message {
    font-size: 0.8em;
    max-width: 26rem;
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
}
