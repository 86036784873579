@media (max-width: 667px) {
    main {
        padding-left: 23vw;
    }
}

input[type="search"] {
    margin-bottom: 2rem;
}

h1  {
    text-align: center;
}
.ui.container {
    font-family: 'Lato', sans-serif!important;


}
.error{
    color: #ff4c2f;
}

.ui.header {
    font-family: 'Lato', sans-serif!important;
}
.ui.text.container {
    font-family: 'Lato', sans-serif!important;
}
.ui.inverted.menu {
    border: 0 solid transparent;
    background: #1f2532;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 12;
}
.support-page, .homePage, .mall-statistics-page, .transactions-records, .offer-page {
    width: 87vw!important;
    position: absolute;
    right: 0;
}

.customer-id-page .ag-header-container{
    background-color: white;
}

.customer-id-page .statutColumn{
    margin-left: 0 !important;
    text-align: left !important;
}

.customer-id-page .ag-theme-balham .ag-header-cell-label .ag-header-cell-text{
    margin: 0 !important;
}

.ui.modal.customer-support-error-modal{
    min-width: 400px;
}

.ui.form input[type=search] {
    margin-bottom: 2rem;
    line-height: 31px;
}

body {
    text-align: center;
}

.pull-right {
    float: right;
    margin-right: 0.25em;
}

.ui.form .field {
    clear: both;
    margin: 1rem auto;
}

.big-form .col-3 .field {
    width: 33%
}

.big-form .col-4 .field {
    width: 25%
}

.big-form .col-5 .field {
    width: 20%
}

.label-left .ui.label {
    float: left
}

.ui.primary.button, .ui.primary.buttons .button {
    background-color: transparent;
    text-shadow: none;
    background-image: none;
    color: #252b33;
    border: 1px solid #252b33;
    text-transform: uppercase;
    font-size: .8rem;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    background-color: #252b33;
    color: #fff;
    text-shadow: none;
}

.ui.mini.button.error-button {
    border-radius: 25px !important
}
.ui.primary.button:focus, .ui.primary.buttons .button:focus {
    background-color: #252b33;
    color: #fff;
    text-shadow: none;
}

.button-search-customer-id.disabled{
    background-color: #00000073 !important
}

.ui.animated.button.button-search-customer-id:hover, .ui.animated.button.error-button:hover{
    padding-right: 40px !important;
}

.ui.primary.button, .ui.primary.buttons .button {
    background-color: transparent;
    text-shadow: none;
    background-image: none;
    color: #252b33;
    border: 1px solid rgba(37, 43, 51, 0.27);
    text-transform: uppercase;
    font-size: .8rem;
}

.ui.form input[type=search] {
    max-width: 19rem;
    margin-right: 1rem;
}

.ui.container {
    margin-top: 0;
}


.ui.tiny.message {
    font-size: 0.8em;
    max-width: 26rem;
    margin: auto;
}

.app-container {
    min-height: 100vh;
}

.statistic-board {
    background-color: #1f2532;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ui.modal.transition.visible.active.error-form-container.error-form-container{
    margin-top: 150px !important;
}

.statutColumn{
    text-align: left !important;
    margin-left: 60px !important;
}

.etatColumn{
    text-align: left !important;
}

.icon-eye{
    width: 13px;
}

.icon-eye-email{
    width: 13px;
    margin-left: 15px
}

.option-store-list{
    background-color: white;
}

.ui.top.attached.header {
    border: none;
}

.justify-center {
    justify-content: center !important;
}

.align-left {
    text-align: left
}

.black-switch.ui.toggle.checkbox input:checked~.box:before, .black-switch.ui.toggle.checkbox input:checked~label:before {
    background-color: #1b1c1d!important;
}

/* add class custom switch to semantic ui element radio to show a custom switch */
.custom-switch.ui.toggle.checkbox input:checked~.box:before,.custom-switch.ui.toggle.checkbox input:checked~label:before {
    background-color: #1b1c1d!important;
    text-align: left;
    color: white;
    content: "Yes";
    padding-left: 1rem;
}

.custom-switch.ui.toggle.checkbox .box:before, .custom-switch.ui.toggle.checkbox label:before {
    background-color: #1b1c1d!important;
    text-align: right;
    color: white;
    content: "No";
    padding-right: 1.2rem;
    width: 8rem;
    height: 3rem;
    border-radius: 0.3rem;
    padding-top: 0.7rem;
}

.custom-switch.ui.toggle.checkbox .box:after, .custom-switch.ui.toggle.checkbox label:after {
    width: 4rem;
    height: 3rem;
    padding-top: 0.7rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    top: 0;
    left: 0;
}

.custom-switch.ui.toggle.checkbox input:checked~.box:after, .custom-switch.ui.toggle.checkbox input:checked~label:after {
    left: 4rem;
    content: "No" !important;
    color: lightgray;
    border-radius: 0 0.3rem 0.3rem 0;
}

.custom-switch.ui.toggle.checkbox input~.box:after, .custom-switch.ui.toggle.checkbox input~label:after {
    content: "Yes" !important;
    color: lightgray;
    border-radius: 0.3rem 0 0 0.3rem;
}

.logout-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    text-align: right;
    box-shadow: none!important;
}

.statistic-board-logout-menu {
    background-color: #1f2532 !important;
}

.ui.top.attached.header {
    height: 7.5rem;
    border-radius: .28571429rem .28571429rem 0 0;
    vertical-align: middle;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    border-bottom: none;
    margin-top: 3rem;
}

.backuser-email {
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
}

.statistic-board-backuser-email {
    color: white;
}

.statistic-board-logout {
    color: white;
}

.ui.top.attached.header {
    height: 4.5rem;
    border-radius: .28571429rem .28571429rem 0 0;
    vertical-align: middle;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    border-bottom: none;
    margin-top: 3rem;
    font-size: 18px!important;
}

.ui.green.button, .ui.green.buttons .button {
    background-color: #00C455;
}

.ui.blue.button, .ui.blue.buttons .button {
    background-color: #00C455!important;
    color: #fff;
    text-shadow: none;
    background-image: none;
}


.ui.green.button:active, .ui.green.buttons .button:active {
    background-color: #00b5ad!important;
    color: #fff;
    text-shadow: none;
}
.ui.green.button:hover, .ui.green.buttons .button:hover {
    background-color: #00b5ad!important;
    color: #fff;
    text-shadow: none;
}

.ui.dropdown .menu>.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0,0,0,.87);
    padding: .6571429rem .84285714rem!important;
    text-transform: none;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-touch-callout: none;
    font-size: 11px;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    text-align: center;
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
    display: flex;
    align-items: center;
    justify-content: center;
}


.tab-customer-support .ag-header-row{
    background-color: white !important;
    border: none;
}

.tab-customer-support .ag-theme-balham .ag-row {
    border: none
}

.ag-theme-balham .ag-root{
    border-radius: 5px;
}

.ag-theme-balham .ag-paging-panel{
    border-top: 0;
}

.tab-customer-support .ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after{
    border: none;
}

.tab-customer-support .ag-theme-balham .ag-header{
    border: none
}

.tab-customer-support .ag-header-viewport{
    color: #202432
}
.tab-customer-support{
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab-customer-support .ag-header-cell-label{
    width: 100%
}

.tab-customer-support .ag-header-cell-text{
    margin: auto
}

.tab-customer-support .ag-theme-balham .ag-cell{
    color: #00000073
}
.tab-customer-support .ag-paging-row-summary-panel{
    display: none
}

.disabledInput{
    opacity: .45;
}

.ag-cell-focus,.ag-cell-no-focus{
     border:none !important;
 }
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus{
    border:none !important;
    outline: none;
}

.edit-user-btn, .delete-user-btn  {
    background-color: #f2711c;
    color: white!important;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    color: rgba(0,0,0,.6);
    margin: 0 .25em 0 0;
    padding: 5px 0;
    text-transform: none;
    width: 80px;
    text-shadow: none;
    font-weight: 700;
    /* line-height: 1em; */
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}


.edit-user-btn, .delete-user-btn, .pending-receipt-btn, .validated-receipt-btn, .rejected-receipt-btn, .duplicate-receipt-btn, .underReview-receipt-btn   {
    background-color: #ffc107d6;
    color: white!important;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    color: rgba(0,0,0,.6);
    margin: 0 .25em 0 0;
    padding: 5px 0;
    text-transform: none;
    width: 90px;
    text-shadow: none;
    font-weight: 700;
    /* line-height: 1em; */
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}
.validated-receipt-btn, .rejected-receipt-btn,  .duplicate-receipt-btn, .pending-receipt-btn   {
    text-transform: initial;
    font-size: 11px;
    letter-spacing: 1.4px
}


.p-iban{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.pending-receipt-btn {
    color: #000000d9!important;
    background-color: white;
    border: 1px solid #00000047;
    font-weight: 500;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    font-size: 11px;
    letter-spacing: 1.4px
}
.pending-receipt-btn:hover {
    background-color: #0000000d;
}
.validated-receipt-btn {
    background-color: #b3e1f7;
}
.validated-receipt-btn {
    background-color: #b3e1f7;
}
.rejected-receipt-btn {
    background-color: #d32f2fbf;
}
.ag-body-container:not(.ag-layout-print) {
    display: block;
    opacity: .9!important
}
.ag-theme-material {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.77)!important;
    font: 400 14px "Roboto", sans-serif;
}
.delete-user-btn {
    background-color: #E63935;
}
.ui.modal,
.ui.active.modal {
    margin: 0 auto!important;
    top: 20% !important;
    left: auto !important;
    transform-origin: center !important;
    transition: all ease .3s;
}

.visible.transition {
    margin-top: auto !important;
    display: inline-block !important;
    position: relative;
    top: 20%;
}

.ag-theme-material .ag-cell {
    text-align: left;
}

.ui.multiple.search.dropdown {
    margin: 1rem 0!important;
}

.ui.pointing.label, .ui[class*="pointing above"].label {
    margin-top: 0;
}

.ui.label {
    background-color: #e8e8e82b;
}

.ui.modal>.content {
    padding: 1rem!important
}

.ui.header:first-child {
    font-size: 14px;
}
.ag-font-style {
    user-select: initial;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
}

.ag-theme-material .ag-ltr .ag-cell-focus {
    border-color: transparent;
    outline: medium none invert;
    outline: initial;
}

.ui.attached.negative.message, .ui.negative.message {
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    font-size: 12px!important;
}

.user-management-label-success {
    background-color: red!important;
    position: absolute!important;
    bottom: 5rem;
    right: 3rem;
    height: 3rem;
}

.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 22px!important;
    border-radius: 1px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}

.Toastify__toast-body h4 {
    margin-bottom: 10px!important;
}


.ag-theme-material .ag-row-selected {
    background-color: #00bcd41f;
}

.receipt-management-modal .ui.modal, .ui.active.modal:not(.amount-validation-modal) {
    top: 2vh!important;
    margin-bottom: 3rem!important;
    transition: .8s ease all
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}

.receipt-management-modal .ui.search.dropdown {
    margin-left: 0!important;
}

.receipt-management-modal-transaction-info p {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;

}
.receipt-management-modal-transaction-info span {
    font-size: 12px;
    font-weight: 600;
    text-transform: initial;


}

.receipt-management-modal-transaction-info h4 {
    font-size: 13px;
    margin-top: 0;
}

.ag-body.ag-layout-normal {
    flex: 1 1;
    height: 0px;
    min-height: 0px;
    font-size: 11px;
}




/* CUSTOMER SUPPORT PAGE*/

.customer-id-page .button{
    border-radius: 25px !important
}

.customer-id-page .p-iban:hover{
    cursor: pointer !important;
}

.customer-id-page .ui.green.circular.empty.label.label-custmomer-id{
    padding: 5px !important;
}
.customer-id-page .ui.empty.circular.label.state{
    padding: 3px !important;
    margin-right: 8px !important;
}

.customer-id-page .react-phone-number-input__icon{
    border: none;
    width: 1.31em;
    height: 1.7em;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.customer-id-page .react-phone-number-input__input.react-phone-number-input__phone{
    width: 300px !important;
    height: 40px !important;
    border: none !important;
}

.customer-id-page .react-phone-number-input__row{
    border: 1px solid #00000073 !important;
    border-radius: 5px;
    padding-left: 13px;
}
.tab-customer-support{
    justify-content: flex-start !important;
}
.container-table-row-1 > div:first-child{
    width: 48.5% !important;
}

.container-table-row-1 > div:last-child{
    width: 48.5% !important;
}

.container-table-row-3 > div{

}

.container-table-row-2 > div {
    width: 100% !important;
}

.tab-customer-support{
    justify-content: space-between !important;
}

.clickable {
    font-weight: bold;
    text-decoration: underline;
    color: rgba(0,0,0,.87);
    padding-left: 1rem;
}

.clickable:hover {
    cursor: pointer;
}

@media screen and (min-width: 1400px) and (max-width: 1900px) {


}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  /*  .container-table-row-1 > div:first-child, .container-table-row-1 > div:last-child, .container-table-row-3 > div  {
        width: 91% !important;
    }

    .container-table-row-2 > div {
        width: 96% !important;
    }

    .tab-customer-support{
        justify-content: flex-start !important;
    }*/

}


@media screen and (min-width: 1280px) and (max-width: 1750px) {
    .container-customer-id {
        width: 60%
    }

    .tab-customer-support{
        justify-content: flex-start !important;
    }

    .container-table-row-1 {
        align-items: flex-start;
    }



    .container-table-row-2 > div {
        width: 100% !important;
    }

    .container-table-row-2 .tab-customer-support .ag-theme-balham {
        min-height: 100px !important;
    }

    .container-table-row-3 > div {
        width: 80% !important;
    }
}


react-autosuggest__container {
    position: relative;
    width: 100%;
}

.react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 10px;
    /* font-family: Helvetica, sans-serif;*/
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}
.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    /*font-family: Helvetica, sans-serif;*/
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__suggestion-match {
    color:  rgb(214, 11, 81);
    font-weight: bold;
}
.react-autosuggest__suggestions-container {
    width: 100%!important;
}
