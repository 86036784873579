.tooltip {
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 60px;
  height: 28px;
  padding: 2px;
  font: 12px "Avenir";
  background: red;
  color: white;
  z-index: 10;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

.my_icon {
  cursor: pointer !important;
}

.lead {
  font-style: italic;
  color: red;
  font-size: 5px;
}

.danger {
  border: 2px black solid;
}
