.card {
  background: #fff;
  border-radius: 2px;
  height: 20rem;
  margin: 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20rem;
}
.card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-container {
  display: flex;
  justify-content: center;
}

.card-container a {
  color: #4183c4;
  font-size: 1.7rem;
  text-decoration: underline;
}
.card a {
  font-size: 1.5rem;
  color: #1C3A52;
}

.visible.transition {
  display: block!important;
}

.homePage h1 {
  font-size: 16px;
  text-transform: uppercase;
}


.homePage {
}

.ReactTable .rt-thead.-header {
  box-shadow: none!important;
}
