.transactions-records input {
  margin: 0 1rem!important;
}


.ui.form .fields {
  justify-content: center;
}

.ui.green.button {
  align-self: flex-end;
}

.card-hub-setup-info {
  position: absolute;
  top: 0;
  right: 3rem;
  text-align: left;
}

.hub-setup-form-container {
  position: relative;
  font-size: 12px;
}

.hub-setup-form-container span {
  font-weight: 900;
}

.ui.table {
  width: 80%;
  border: 1px solid rgba(34,36,38,.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: .28571429rem;
  text-align: left;
  color: rgba(0,0,0,.87);
  border-collapse: separate;
  border-spacing: 0;
  margin: 2rem auto;
}

.transaction-validation-modal span {
  font-weight: 900;
  margin-right: .5rem;
}

.transaction-validation-modal p {
  font-size: 12px;
  margin: 5px 0;

}

.ui.compact.message {
  margin-top: 1rem;
}

.ui.dimmer {
  background-color: rgba(28, 58, 82, 0.6)!important;
}
.ui.attached.info.message, .ui.info.message {
  -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
  font-size: 12px!important;
}
.ui.modal .actions>.button {
  margin-left: .75em;
  width: 8rem;
}

.card-hub-setup-info {
  position: absolute;
  top: 4rem;
  right: 3rem;
  text-align: left;
}

.ui.blue.button {
  align-self: flex-end;
}
@media only screen and (max-width: 768px) {
  .ui.selectable.table thead tr{
    display: none!important
  }
  .card-hub-setup-info {
    position: relative;
    top: 0;
    right: 3rem;
    text-align: left;
    margin-bottom: 2rem;
  }

  .support-page, .homePage, .mall-statistics-page, .transactions-records {
    width: 100vw!important;
     position: relative!important;
    margin: auto!important;
  }

  .ui.green.button, .ui.blue.button {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-top: 1rem;
    height: 3rem;
    width: 76%;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .ui.blue.button {
    margin-top: 0;
  }
  .ui.form .sixteen.wide.field {
    padding: 0;
  }
  .ui.form .field .ui.input input, .ui.form .fields .field .ui.input input {
    margin: 0!important;
  }
  .ui.form .fields {
    margin: 0!important;
  }

  .ui.form .message:first-child {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .ui.attached.info.message, .ui.info.message {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    font-size: 12px!important;
  }
  .ui.form .fields>.field:first-child {
    border-left: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }
  .actions button {
    width: 50%!important;
    margin-top: 0!important;
  }
  .card-hub-setup-info {
    right: 3rem;
    text-align: left;
    margin-top: -4rem;
    left: 2rem;
  }
  .ui.modal>.actions {
    background: #F7F9FC!important;
    padding: 1rem 1rem;
    border-top: 1px solid rgba(34,36,38,.15);
    text-align: right;
    display: flex;

  }
  .ui.info.message {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    font-size: 12px!important;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-hub-setup-info {
    right: 3rem;
    text-align: left;
    margin-top: -5rem;
    left: 2rem;
  }
  .card-hub-setup-info p {
    margin-bottom: 3px;
  }
}
