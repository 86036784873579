div {
  .rc-slider-track {
    background: linear-gradient(to right,
        rgba(171, 230, 255, 1) 0%,
        rgba(171, 230, 255, 1) 29%,
        rgba(85, 212, 250, 1) 52%,
        rgba(105, 177, 250, 1) 81%,
        rgba(105, 177, 250, 1) 100%) !important;
  }
  #sendOfferButton{
    padding-right: 4.07142857em!important;
    padding-left: 1.5em!important;
  }
}
