.my_container {
  font-family: "Avenir" !important;
  background: white;
}

.my_arrow {
  font-size: "10px" !important;
}

.gly-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

.gly-rotate-minus45 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: inline-block;
}

.statloader {
  height: 200px !important;
}

.avenir {
  font-family: "Avenir" !important;
}

.title {
  font-weight: bold !important;
  color: #4b74ff !important;
}

.headerVeryStrong {
  color: #67ff00 !important;
}

.headerWeak {
  color: #f45642 !important;
}

.headerVeryWeak {
  color: #f44141 !important;
}

.toptab {
  border: 0 !important;
  box-shadow: none !important;
}

.offer-page .secondary{
  margin-top: 50px !important;
}
.offer-page .ui.segment {
  background-color: white!important;
  color: rgba(0,0,0,.87)!important;
}
